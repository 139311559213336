import { createApp, Vue } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/md-light-indigo/theme.css'

import PrimeVue from 'primevue/config';
import Timeline from 'primevue/timeline';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Toolbar from 'primevue/toolbar';
import Avatar from 'primevue/avatar';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import Breadcrumb from 'primevue/breadcrumb';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import DataView from 'primevue/dataview';
import OverlayPanel from 'primevue/overlaypanel';
import Badge from 'primevue/badge';
import Ripple from 'primevue/ripple';
import BadgeDirective from 'primevue/badgedirective';
import StyleClass from 'primevue/styleclass';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                 
import Panel from 'primevue/panel';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import ProgressBar from 'primevue/progressbar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Image from 'primevue/image';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';

const MyApp = createApp(App)
MyApp.use(store)
MyApp.use(router)
MyApp.use(PrimeVue);
MyApp.use(ToastService);

MyApp.component('Timeline', Timeline);
MyApp.component('Button', Button);
MyApp.component('Card', Card);
MyApp.component('Toolbar', Toolbar);
MyApp.component('Avatar', Avatar);
MyApp.component('InputMask', InputMask);
MyApp.component('InputText', InputText);
MyApp.component('Checkbox', Checkbox);
MyApp.component('Password', Password);
MyApp.component('Breadcrumb', Breadcrumb);
MyApp.component('FileUpload', FileUpload);
MyApp.component('Toast', Toast);
MyApp.component('DataView', DataView);
MyApp.component('OverlayPanel', OverlayPanel);
MyApp.component('Badge', Badge);
MyApp.component('DataTable', DataTable);
MyApp.component('Column', Column);
MyApp.component('ColumnGroup', ColumnGroup);
MyApp.component('Row', Row);
MyApp.component('Panel', Panel);
MyApp.component('Tag', Tag);
MyApp.component('Dialog', Dialog);
MyApp.component('InputNumber', InputNumber);
MyApp.component('InputSwitch ', InputSwitch );
MyApp.component('ProgressBar', ProgressBar);
MyApp.component('ProgressSpinner', ProgressSpinner);
MyApp.component('Accordion', Accordion);
MyApp.component('AccordionTab', AccordionTab);
MyApp.component('Image', Image);
MyApp.component('Calendar', Calendar);
MyApp.component('Fieldset', Fieldset);


MyApp.directive('badge', BadgeDirective);
MyApp.directive('ripple', Ripple);
MyApp.directive('styleclass', StyleClass);


MyApp.mount('#app')