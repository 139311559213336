export default {
    path: '/test',
    name: 'test',
    component:() => import(/* webpackChunkName: "test layout" */ '@/modules/testing/layouts/TestLayout.vue'),
    children: [
        {
            path: 'payment',
            name: 'payment',
            component:() => import(/* webpackChunkName: "payment test" */ '@/modules/public/views/PaymentTest.vue'),
        }
    ]
}