export default {
    path: '/public',
    name: 'public',
    component:() => import(/* webpackChunkName: "public layout" */ '@/modules/public/layouts/PublicLayout.vue'),
    children: [
        {
            path: '',
            name: 'buscaPredial',
            component:() => import(/* webpackChunkName: "ListasPrecio" */ '@/modules/public/views/buscaPredial.vue'),
        },
        {
            path: 'edoCuenta/:expediente',
            name: 'edoCuenta',
            component:() => import(/* webpackChunkName: "ListasPrecio" */ '@/modules/public/views/EstadoCuenta.vue'),
        },
        {
            path: 'pagoEnBanco',
            name: 'pagoEnBanco',
            component:() => import(/* webpackChunkName: "ListasPrecio" */ '@/modules/public/views/PagoEnBanco.vue'),
        },
        {
            path: 'comprobante',
            name: 'comprobante',
            component:() => import(/* webpackChunkName: "ListasPrecio" */ '@/modules/public/views/Comprobante.vue'),
        },
        {
            path: 'pagoexitoso',
            name: 'pagoexitoso',
            component:() => import(/* webpackChunkName: "exito" */ '@/modules/public/views/PagoExitoso.vue'),
        },
        {
            path: 'pagoConError',
            name: 'pagoConError',
            component:() => import(/* webpackChunkName: "exito" */ '@/modules/public/views/PagoConError.vue'),
        }
    ]
}