export default {
    path: '/auth',
    name: 'auth',
    component:() => import(/* webpackChunkName: "admin auth layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
        {
            path: '',
            name: 'login',
            component:() => import(/* webpackChunkName: "Login" */ '@/modules/auth/views/Login.vue'),
        },
        {
            path: 'recupera',
            name: 'recupera',
            component:() => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/RecuperaPassword.vue'),
        },
        
    ]
}