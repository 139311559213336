export default {
    path: '/payment',
    name: 'pagos',
    component:() => import(/* webpackChunkName: "pagos " */ '@/modules/pagos/layouts/PagosLayout.vue'),
    children: [
        {
            path: 'recibo/:url',
            name: 'recibo',
            component:() => import(/* webpackChunkName: "recibo1" */ '@/modules/pagos/views/Recibo.vue'),
        },
        {
            path: 'multipagos.error/:mpAuthorization',
            name: 'error',
            component:() => import(/* webpackChunkName: "errorPago" */ '@/modules/pagos/views/error.vue'),
        },
        {
            path: 'multipagos.enproceso/:mpAuthorization',
            name: 'enproceso',
            component:() => import(/* webpackChunkName: "EnProceso" */ '@/modules/pagos/views/EnProceso.vue'),
        }
]
}