import { createRouter, createWebHashHistory } from 'vue-router'
import  testTouter  from '../modules/testing/router'
import  pagosRouter  from '../modules/pagos/router'
import  pubRouter  from '../modules/public/router'
import  authRouter  from '../modules/auth/router'
import  adminRouter  from '../modules/admin/router'
import isAuthenticatedGuard from '@/router/auth-guard'
import createStore from '../store'

const routes = [
  {
    path: '/',
    redirect: () => {
      return '/public'
    }
  },
  {
    path: '/public',
    ...pubRouter
  },
  {
    path: '/auth',
    ...authRouter
  },
  {
    path: '/admin',
    beforeEnter: [ isAuthenticatedGuard ],
    ...adminRouter
  },
  {
    path: '/payment',
    ...pagosRouter
  },
  {
    path: '/test',
    ...testTouter
  }
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
