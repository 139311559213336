import { createStore } from 'vuex'
import authApi from '@/api/authApi'
export default createStore({
  state: {
    idUsuario: -1,
    email: '',
    token:'',
    displayName:'',
    idRol: -1,
    sessionStatus: 'No signed',
    status: 'authenticating', // 'authenticated','not-authenticated', 'authenticating',
    edoCuenta: {
      id:0,
      expediente:'',
      numCarga:-1,
      fCarga:null
    }
  },
  getters: {
    edoCuenta(state) {
      return state.edoCuenta;
    }
  },
  mutations: {
    setCatalogos(state, catalogos) {
      state.periodos = catalogos.periodos
      state.monedas = catalogos.monedas
    },
    loginUser( state, userSession) {
      state.idUsuario = userSession.idUsuario,
      state.idRol = userSession.idRol
      state.email = userSession.email
      state.token = userSession.token
      state.displayName = userSession.displayName
      state.sessionStatus = 'authenticated'
      localStorage.setItem( 'token', state.token )
      localStorage.setItem( 'idUsuario', state.idUsuario )
    },
    logoffUser( state) {
      state.idUsuario = -1
      state.idRol = 0
      state.email = ''
      state.token = ''
      state.displayName = ''
      state.sessionStatus = 'logged off'
      localStorage.setItem( 'token', '' )
      localStorage.setItem( 'idUsuario', state.idUsuario )
    },
    mutSetEdoCuenta(state, estadoCuenta) {
      state.edoCuenta = estadoCuenta
    }

  },
  actions: {
    async setCatalogos ({commit}, catalogos) {
      commit('setCatalogos', catalogos)
    },
    async signInUser ({ commit }, emailPassword )  {
      const {email, password} = emailPassword
      console.log(authApi)
      const baseURL= process.env.VUE_APP_BACKEND_URL
      try {          
          const { data } = await authApi.post('/login',null,{ params: {email ,password}})
          const userSession = { 
            idUsuario: data.id_usuario,
            idRol: data.id_rol,
            email: data.email,
            displayName: data.nombre + ' ' + data.a_paterno + ' ' + data.a_materno,
            token: data.token,
          }
          if (data.respuestaM.idStatus == 0) {
            commit('loginUser', userSession)
            return { ok: true,message:data.respuestaM.mensaje }
          } else {
            return { ok: false,message:data.respuestaM.mensaje }
          }
      } catch (error) {
          console.log("Error==>")
          console.log(error)
          
          return { ok: false, message: error.message }
      }
    },
    async logout ({ commit } )  {
      try { 
        commit('logoffUser')
        return { ok: true,message:'Ok logout' }         
      } catch (error) {
          console.log("Error==>")
          console.log(error)          
          return { ok: false, message: 'Logged off with error' }
      }
    },
    async setEdoCuenta({ commit },estadoCuenta) {
      const tmpEdoCuenta = {
        id:          estadoCuenta.id,       
        expediente:  estadoCuenta.expediente, 
        numCarga:    estadoCuenta.numCarga,
        fCarga:      estadoCuenta.fCarga 
      }
      commit('mutSetEdoCuenta', tmpEdoCuenta)
    },
    async clearEdoCuenta({ commit }) {
      const tmpEdoCuenta = {
        id:0,
        expediente:'',
        numCarga:-1,
        fCarga:null
      }
      commit('mutSetEdoCuenta', tmpEdoCuenta)
    }
  },
  modules: {
  }
})
