import axios from 'axios'

const authApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL
   
      /*  params: {
        token: 'MySecret!!'
     ,
        email:'---alejandro@quintero.com',
        password:'----sd' 
    }*/
})

 

export default authApi