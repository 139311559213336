import isAuthenticatedGuard from '@/router/auth-guard'
import createStore from '@/store'

export default {
    path: '/admin',
    name: 'admin',
    redirect: () => {
        if (createStore.state.idUsuario < 0) {     
         return '/auth/'       
        } else {
          return '/admin/archivoPredial'
        }
      },
    component:() => import(/* webpackChunkName: "admin auth layout" */ '@/modules/admin/layouts/AdminLayout.vue'),
    children: [
        {
            path: 'home',
            name: 'home',
            component:() => import(/* webpackChunkName: "home" */ '@/modules/admin/views/Principal.vue'),
        },
        {
            path: 'archivoPredial',
            name: 'archivoPredial',
            component:() => import(/* webpackChunkName: "ArchivoPredial" */ '@/modules/admin/views/ArchivoPredial.vue'),
        },
        {
            path: 'conciliacion',
            name: 'conciliacion',
            component:() => import(/* webpackChunkName: "conciliacion" */ '@/modules/admin/views/Conciliacion.vue'),
        },
        {
            path: 'estadisticas',
            name: 'estadisticas',
            component:() => import(/* webpackChunkName: "estadisticas" */ '@/modules/admin/views/Estadisticas.vue'),
        },
        {
            path: 'generarArchivo',
            name: 'generarArchivo',
            component:() => import(/* webpackChunkName: "generarArchivo" */ '@/modules/admin/views/GenerarArchivo.vue'),
        },
        {
            path: 'configuracion',
            name: 'configuracion',
            component:() => import(/* webpackChunkName: "configuracion" */ '@/modules/admin/views/ActualizaParametros.vue'),
        },
        {
            path: 'pagosRecibidos',
            name: 'pagosRecibidos',
            component:() => import(/* webpackChunkName: "pagosRecibidos" */ '@/modules/admin/views/PagosRecibidos.vue'),
        },
    ]
}